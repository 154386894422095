<template>
    <div>

        <div class="swiper ad" :style="{width: width + 'px'}">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item) in list" :key="item.code">
                    <img :src="item.face_img" alt="" @click="openArticleDetail(item)">
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>
<script>
import article from '@/mixins/article'
export default {
    mixins: [article],

    props: {
        width: {
            type: Number,
            default: 976,
        },
        list: {
            type: Array,
            default: () => []
        }
    },


    mounted() {
        console.log('this.list', this.list.length)

        //   var swiper = 
        new window.Swiper(".ad", {
            spaceBetween: 8,
            centeredSlides: true,
            loop: true,
            autoplay: {
                delay: 2500,
                // disableOnInteraction: false,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });

    }
}
</script>

<style lang="less" scoped>
.swiper {
    // width: 976px;
    height: 200px;

    .swiper-slide img {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: fill;
        cursor: pointer;
    }
}
</style>