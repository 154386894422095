<template>
    <div class="container">
        <Card title="热点专题" url="/hot-detail">
            <SpecialNews :list="specialNews" v-if="specialNews.length > 0" />
        </Card>
        <Padding />
        <Card title="苏苏说天气" :extra='false'>
            <Category :list="sssWeatherList" />
            <div class="center pagination">
                <a-pagination size="small" :total="total" :show-total="(total) => `共 ${total} 条记录`" :page-size="9"
                    :current="current" @change="onChange" />
            </div>
        </Card>
    </div>
</template>

<script>
import Category from './components/category'
import SpecialNews from '@/components/special-news'

import { fetchList } from '@/api/article'
export default {
    components: {
        Category,
        SpecialNews,
    },

    data() {
        return {
            specialNews: [],
            current: 1,
            sssWeatherList: [],
            total: 0,
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    category: "ztxw",
                    page: 1,
                    rows: 4,
                },
         
                    {
                        category: "ssstq",
                        page: this.current,
                        rows: 9,
                    },
                ]
            }).then(res => {
                if (Array.isArray(res) && res.length > 1) {

                    let ztxw = res.find(item => item.category === 'ztxw');
                    if (ztxw && ztxw.data && Array.isArray(ztxw.data.list) && ztxw.data.list.length > 0) {
                        this.specialNews = ztxw.data.list;
                    }

                    let ssstq = res.find(item => item.category === 'ssstq');
                    if (ssstq && ssstq.data && Array.isArray(ssstq.data.list) && ssstq.data.list.length > 0) {
                        this.sssWeatherList = ssstq.data.list;
                        this.total = parseInt(ssstq.data.total)
                    }
                }
            })
        },

        onChange(page) {
            console.log(page)
            this.current = page;
            this.getList();
        },
    }

}
</script>

<style lang="less" scoped>
.pagination {
    margin: 24px 0;
}
</style>