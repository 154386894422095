<template>
    <div class="panel">
        <div class="item" v-for="item in list" :key="item.code" @click="openArticleDetail(item)">
            <img :src="item.face_img" alt="">
            <div class=" footer">
                <span :title="item.title">{{item.title}}</span>
                <span class="date">{{ item.publish_at }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
}
</script>

<style lang="less" scoped>
    .item {
        cursor: pointer;
        border: 1px solid #ebebeb;
        img {
            width: 100%;
        }

        .footer {
            padding: 12px;
                font-size: 12px;
                line-height: 24px;

            .date {
                float: right;
                color: #b6b6b6;
            }
        }
    }


</style>